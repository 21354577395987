import React from 'react';
import {useState} from 'react';
import spells from '../spells.json';
import SpellList from './SpellList.react';
import CardLayout from './base/CardLayout.react';
import SpellSearch from './SpellSearch.react';

export default function App() {
  const [filters, setFilters] = useState({title: ''});
  const toShow = applyFilters(spells, filters);
  return (
    <CardLayout>
      <SpellSearch filters={filters} setFilters={setFilters} />
      <SpellList spells={toShow} />
    </CardLayout>
  );
}

function applyFilters(spells, {title}) {
  let toShow = spells;

  if (title !== '') {
    toShow = toShow.filter(s =>
      s.name.toLowerCase().includes(title.toLowerCase()),
    );
  }

  toShow = [...toShow].sort((a, b) => {
    if (a.level !== b.level) {
      return a.level - b.level;
    }

    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();

    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
  });

  return toShow;
}
