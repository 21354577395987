/* @flow strict */
import * as React from 'react';

import getFont, {Fonts} from '../../styles/Fonts';
import getColor, {Colors} from '../../styles/Colors';
import CardConstants from './CardConstants';

type Props = {|
  children: React.Node,
|};

export default function CardLayout({children}: Props) {
  return (
    <div
      style={{
        background: getColor(Colors.ThemeDarkRed),
        color: getColor(Colors.TextGreyDark),
        fontFamily: getFont(Fonts.Sans),
        minHeight: '100vh',
        width: '100%',
      }}
    >
      <div
        style={{
          alignContent: 'space-around',
          alignItems: 'flex-start',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          margin: '0 auto',
          width: '100%',
          ...CardConstants.Layout,
        }}
      >
        {children}
      </div>
    </div>
  );
}
