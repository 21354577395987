/* @flow strict */

import nullthrows from 'nullthrows';
import keymirror from 'keymirror';

const colorMap = {
  BackgroundGreyLight: '#3B3C36',
  BackgroundGreyDark: '#222222',
  BackgroundWhite: '#F2F3F5',

  ThemeDarkRed: '#9b1631',
  ThemeDarkBlue: '#003268',
  ThemeBrightRed: '#E3132C',

  TextGreyLight: '#949597',
  TextGreyDark: '#252525',

  WebkitFocusColor: '#5E9ED6',
};

export const Colors = keymirror(colorMap);

export default function getColor(color: $Keys<typeof Colors>) {
  return nullthrows(colorMap[color]);
}
