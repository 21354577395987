/* @flow strict */

import nullthrows from 'nullthrows';
import keymirror from 'keymirror';

const fontMap = {
  Serif: "'Slabo 27px', serif",
  Sans: "'Open Sans', sans-serif",
};

export const Fonts = keymirror(fontMap);

export default function getFont(font: $Keys<typeof Fonts>) {
  return nullthrows(fontMap[font]);
}
