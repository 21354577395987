/* @flow strict */
import * as React from 'react';
import CardConstants from './CardConstants';

type Props = {|
  children: React.Node,
|};

export default function TitleCard({children}: Props) {
  return <div style={CardConstants.TitleCard}>{children}</div>;
}
