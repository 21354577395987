/* @flow strict */

import getElevation, {Elevations} from '../../styles/Elevations';
import getColor, {Colors} from '../../styles/Colors';

function toPx(value: number) {
  return `${value}px`;
}

const CARD_WIDTH = 400;
const CARD_MARGIN = 12;

const CardConstants = {};

CardConstants.Layout = {
  maxWidth: toPx(2 * (CARD_WIDTH + 2 * CARD_MARGIN)),
};

CardConstants.Card = {
  background: getColor(Colors.BackgroundWhite),
  borderRadius: '2px',
  margin: toPx(CARD_MARGIN),
  padding: '16px',
  maxWidth: toPx(CARD_WIDTH),
  textAlign: 'left',
  width: '100%',
  animation: 'slide-up 0.4s ease',
  ...getElevation(Elevations.Elevation1),
};

CardConstants.Hover = {
  ...CardConstants.Card,
  ...getElevation(Elevations.Elevation3),
};

CardConstants.TitleCard = {
  ...CardConstants.Card,
  textAlign: 'left',
  maxWidth: toPx(2 * (CARD_WIDTH + CARD_MARGIN)),
};

export default CardConstants;
