/* @flow strict */

import nullthrows from 'nullthrows';
import keymirror from 'keymirror';

const elevationMap = {
  Elevation0: '',
  Elevation1: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
  Elevation2: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
  Elevation3: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
  Elevation4: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
  Elevation5: '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)',
};

export const Elevations = keymirror(elevationMap);

export default function getElevation(elevation: $Keys<typeof Elevations>) {
  return {
    boxShadow: nullthrows(elevationMap[elevation]),
    transition: 'box-shadow 0.3s cubic-bezier(.25, .8, .25, 1)',
  };
}
