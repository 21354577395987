/* @flow strict */
import type {Node} from 'react';
import React, {useState} from 'react';
import CardConstants from './CardConstants';

type Props = {|
  children: Node,
|};

export default function Card({children}: Props) {
  const [isHovering, setHover] = useState(false);
  return (
    <div
      style={isHovering ? CardConstants.Hover : CardConstants.Card}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {children}
    </div>
  );
}
