import React from 'react';
import Card from './base/Card.react';
import assertExhausted from '../assertExhausted';
import ReactMarkdown from 'react-markdown';

const MAX = 4;

export default function SpellList({spells}) {
  return (
    <>
      {spells.slice(0, MAX).map(s => (
        <SpellListItem key={s.id} {...s} />
      ))}
    </>
  );
}

function SpellListItem({
  id,
  name,
  school,
  level,
  casting_time,
  ritual,
  range,
  components,
  duration,
  description,
  at_higher_levels,
  source,
  tags,
}) {
  return (
    <Card>
      <h2>{name}</h2>
      <h3>
        <Description level={level} school={school} ritual={ritual} />
      </h3>
      <p>
        <strong>Casting Time: </strong>
        {casting_time}
      </p>
      <p>
        <strong>Range: </strong>
        {range}
      </p>
      <p>
        <strong>Components: </strong>
        {components}
      </p>
      <p>
        <strong>Duration: </strong>
        {duration}
      </p>
      <p>
        <strong>Source: </strong>
        {source}
      </p>
      <p>
        <ReactMarkdown source={description} />
      </p>
      {at_higher_levels === '' ? null : (
        <p>
          <strong>At Higher Levels: </strong>
          <ReactMarkdown source={at_higher_levels} />
        </p>
      )}
    </Card>
  );
}

function Description({level, school, ritual}) {
  const postfix = ritual ? ' (ritual)' : '';

  function getString() {
    switch (level) {
      case 0:
        return `${school} cantrip`;
      case 1:
        return `1st-level ${school.toLowerCase()}`;
      case 2:
        return `2nd-level ${school.toLowerCase()}`;
      case 3:
        return `3rd-level ${school.toLowerCase()}`;
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
        return `${level}th-level ${school.toLowerCase()}`;
      default:
        assertExhausted(level);
    }
  }

  return `${getString()}${postfix}`;
}
