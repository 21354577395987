import React from 'react';
import TitleCard from './base/TitleCard.react';
import getColor, {Colors} from '../styles/Colors';

export default function SpellSearch({filters, setFilters}) {
  const {title} = filters;
  return (
    <TitleCard>
      <h1>Grimoire</h1>
      <form onSubmit={ev => ev.preventDefault()}>
        <input
          style={{
            width: '75%',
            height: '1.25em',
            background: 'none',
            border: 'none',
            borderBottom: `1px solid ${getColor(Colors.TextGreyLight)}`,
            outline: 'none',
          }}
          placeholder="What spell shall I find?"
          type="text"
          autoFocus={true}
          value={title}
          onChange={ev => setFilters({...filters, title: ev.target.value})}
        />
      </form>
    </TitleCard>
  );
}
